<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<button
  (click)="onClick.emit($event)"
  type="button"
  class="btn btn-verto"
  [ngClass]="buttonClasses"
  [disabled]="isDisabled"
>
  <ng-content></ng-content>
</button>
