/* Copyright 2023 (Unpublished) Verto Inc. */

export abstract class EngageUtilsConfig {
  assetsPath?: string;
  store?: {
    storage: Storage;
    clearOnInit?: boolean;
    serializer: (value: any) => string;
    deserializer: (value: string) => any;
  };
  apiInterceptor?: {
    errorRedirects: {
      401: {
        redirectTo: string[];
        exclude?: string[];
      };
    };
  };
}
