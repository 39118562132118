/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-paragraph',
  templateUrl: './mood-paragraph.component.html',
  styleUrls: ['./mood-paragraph.component.scss'],
})
export class MoodParagraphComponent {
  @Input() content: string;

  get paragraphContent(): string {
    return valOrEval(this.content);
  }
}
