/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { FormLayout } from '@shared/models/form-layout';
import { SpacingOptions } from '@shared/options';

export class Spacing extends FormLayout {
  elementTypeName: string = 'Spacing';

  height: string;
  width: string;

  constructor(options: SpacingOptions) {
    super(options);
    this.height = options.height;
    this.width = options.width;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      height: this.height,
      width: this.width,
    });
  }

  getEditFormJSON(extraChildren: object[] = []) {
    return super.getEditFormJSON([
      {
        elementType: 'InputText',
        name: 'height',
        label: 'Height',
        required: false,
        colspan: 12,
      },
      {
        elementType: 'InputText',
        name: 'width',
        label: 'Width',
        required: false,
        colspan: 12,
      },
    ]);
  }
}
