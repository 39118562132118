/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { InvisibleContainerOptions } from '@shared/options';
import { FormContainer } from './form-container';

export class InvisibleContainer extends FormContainer {
  elementTypeName: string = 'InvisibleContainer';

  hasBorders: boolean;

  constructor(options: InvisibleContainerOptions) {
    super(options);
    this.hasBorders = options.hasBorders || false;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      hasBorders: this.hasBorders,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputSwitch',
          name: 'hasBorders',
          label: 'Show Borders',
          required: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
