/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Models
import { Accordion } from '@shared/models';

// Components
import { FormContainerComponent } from '@shared/components/form-container/form-container.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent extends FormContainerComponent {
  item: Accordion;
}
