<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="p-fluid p-label" [attr.data-name]="item.name">
  <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
  <app-patch-autocomplete
    [options]="options"
    [item]="item"
    [formControl]="item.formControl"
    (onChangeCallback)="emitEvent()"
  ></app-patch-autocomplete>
</div>
<app-form-validators [item]="item"></app-form-validators>
