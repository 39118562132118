/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { EngageUtilsConfig } from './engage-utils-config';

interface StoreListener {
  key: string;
  cb: (value: any) => void;
}

const ENGAGE_STORE_KEY = 'ENGAGE_STORE_KEY';

/**
 * In practice all of our EngageSerializableStoreService share the same underlying data store.
 * In general this may not be the case, but by relying on this constraint we can quickly ensure that the store
 * is only cleared once regardless of how many EngageSerializableStoreServices exist.
 *
 * todo(aleksanderbodurri): refactor EngageSerializableStoreService so that only 1 instance of it exists per
 * lazy loaded module
 */
let storeClearedAlready = false;

@Injectable()
export class EngageSerializableStoreService {
  private readonly _storage: Storage;
  private readonly _serializer: (value: any) => string;
  private readonly _deserializer: (value: string) => any;

  private _listeners: StoreListener[] = [];

  constructor(private config: EngageUtilsConfig) {
    if (config.store === undefined) {
      throw new Error('Storage type not provided to EngageUtilsModule');
    }

    this._storage = config.store.storage;
    this._serializer = config.store.serializer;
    this._deserializer = config.store.deserializer;

    if (!this._storage.getItem(ENGAGE_STORE_KEY)) {
      this._storage.setItem(ENGAGE_STORE_KEY, '{}');
    }

    if (!storeClearedAlready && config.store.clearOnInit) {
      storeClearedAlready = true;
      this.clear();
    }
  }

  store(key: string, value: any): this {
    const storeSnapshot = this.storeSnapshot;
    storeSnapshot[key] = value;
    this._storage.setItem(ENGAGE_STORE_KEY, this._serializer(storeSnapshot));
    this._fireCallbacks(key, value);

    return this;
  }

  remove(key: string): this {
    const storeSnapshot = this.storeSnapshot;
    delete storeSnapshot[key];
    this._storage.setItem(ENGAGE_STORE_KEY, this._serializer(storeSnapshot));

    return this;
  }

  retrieve(key: string): any {
    return this.storeSnapshot[key];
  }

  has(key: string): boolean {
    return this.storeSnapshot.hasOwnProperty(key);
  }

  clear(): this {
    this._storage.setItem(ENGAGE_STORE_KEY, '{}');

    return this;
  }

  onChange(key: string, cb: (value: any) => void) {
    this._listeners.push({
      key,
      cb,
    });
  }

  offChange(key: string): void {
    this._listeners = this._listeners.filter((listener) => listener.key !== key);
  }

  get storeSnapshot(): any {
    return this._deserializer(this._storage.getItem(ENGAGE_STORE_KEY));
  }

  private _fireCallbacks(key: string, value: any): void {
    this._listeners.forEach((listener) => {
      if (listener.key === key) {
        listener.cb(value);
      }
    });
  }
}
