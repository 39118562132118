/* Copyright 2023 (Unpublished) Verto Inc. */

/*
 * Public API Surface of engage-common
 */

import { InjectionToken } from '@angular/core';
import { from } from 'rxjs';

export { GenericModule } from './lib/generic/generic.module';
export { MahModule } from './lib/mah/mah.module';
export { MoodModule } from './lib/mood/mood.module';
export { MoodState } from './lib/mood/mood-state';
export { KitSubmitModule } from './lib/kit-submit/kit-submit.module';

export { KitClient } from './lib/kit-submit/kit-cli.service';
export { ScreenComponent } from './lib/kit-submit/screen/screen.component';

export * from './lib/mood/mood-renderer/mood-renderer.component';
export * from './lib/mood/mood-renderer/renderer';

export { FormService } from './lib/verto-forms/form.service';
export {
  SlugBasedFormComponent,
  VertoFormsExternalModule,
} from './lib/verto-forms/slug-based-form.component';

export { ProgressBarModule } from './lib/progress/progress.component';

export const APP_KEY = new InjectionToken<string>('Engage Application Key');
export const SHELL_DATA = new InjectionToken(
  'Data from application shell that is accessible to child apps.'
);

// dfd-common
export { MeilisearchService } from './lib/dfd-common/services/meilisearch.service';
export { DistanceFilterService } from './lib/dfd-common/services/distancefilter.service';
export { LocalService } from './lib/dfd-common/services/localstorage.service';

// Services
export { RelativeDatePipe } from './lib/services/relative-date.pipe';
