<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<ng-container *ngIf="buildable; else renderOnly">
  <div class="small-header">{{ item.name }}</div>
  <div
    cdkDropList
    [id]="item.id"
    (cdkDropListDropped)="onDragDrop($event)"
    [cdkDropListConnectedTo]="draggableElementIds"
    [cdkDropListData]="item"
    class="item-dropzone row no-padding"
  >
    <app-dynamic-component
      cdkDrag
      *ngFor="let child of item.children"
      [buildable]="true"
      [item]="child"
      [cdkDragData]="child"
      (click)="onComponentClick(child, $event)"
      class="col-sm-{{ child.getColSpan() }} col-md-{{ child.getColSpan() }} col-lg-{{ child.getColSpan() }}"
      [class.no-padding]="child.elementTypeName.includes('Container') || child.elementTypeName.includes('Fieldset')"
      [class.has-borders]="item.hasBorders"
    >
    </app-dynamic-component>

    <div class="select-component">
      <p-overlayPanel #op>
        <ng-template pTemplate>
          <app-toolbar (componentSelected)="componentSelected($event, op)" mode="select"></app-toolbar>
        </ng-template>
      </p-overlayPanel>
      <button type="text" pButton label="+" class="create-cmp" (click)="toggleDialog(op, $event)"></button>
    </div>
  </div>
</ng-container>

<ng-template #renderOnly>
  <div class="p-grid">
    <app-dynamic-component
      *ngFor="let child of item.children"
      class="child-item p-col-{{ child.getColSpan() }}"
      [item]="child"
      [ngClass]="{ buildable: buildable, hidden: child.hidden }"
      [class.no-padding]="child.elementTypeName.includes('Container') || child.elementTypeName.includes('Fieldset')"
      [class.has-borders]="item.hasBorders"
    >
    </app-dynamic-component>
  </div>
</ng-template>
