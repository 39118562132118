/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Component } from '@angular/core';

// components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// models
import { Button } from '@shared/models/button';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends FormElementComponent {
  item: Button;
}
