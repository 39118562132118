<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div
  *ngIf="page !== undefined && totalPages !== undefined"
  class="outer"
  [style.background-color]="backgroundColor"
  [style.height.px]="progressBarHeight"
>
  <div
    class="inner"
    [style.background-color]="barColor"
    [style.width.%]="(page / totalPages) * 100"
    [style.height.px]="progressBarHeight"
  ></div>

  <div
    *ngFor="let block of blocks"
    class="block"
    [style.background-color]="blockColor"
    [style.left]="block"
    [style.height.px]="progressBarHeight"
  ></div>
</div>
