/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { Calendar } from '@shared/models';

// Locales
import locales from '@root/shared/models/locales/calendar/locale';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent extends FormElementComponent {
  item: Calendar;
  locales = locales;

  localeData() {
    return this.locales[this.item.locale];
  }
}
