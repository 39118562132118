/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { InputSwitch } from '@shared/models/input-switch';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
})
export class InputSwitchComponent extends FormElementComponent {
  item: InputSwitch;
}
