/* Copyright 2023 (Unpublished) Verto Inc. */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { APP_KEY } from 'engage-common';
import { ConfigBasedStorage } from '../services/config-based-storage';
import { SmartOnFhirService } from '../services/smart-on-fhir.service';
import { ShellLoader } from '../ShellLoader';

@Component({
  template: ``,
  standalone: true,
})
export class AuthCodeToAccessTokenExchangeComponent implements OnInit {
  private _router = inject(Router);
  private _appKey: string = inject(APP_KEY);
  private _shellLoader = inject(ShellLoader);
  private http = inject(HttpClient);
  private _route = inject(ActivatedRoute);
  private _smartOnFhirService = inject(SmartOnFhirService);
  private _oauthService = inject(OAuthService);
  private _configBasedStorage = inject(ConfigBasedStorage);

  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      const {
        discoveryDocumentUrl,
        oauth_config: { clientId, redirectUri },
      } = this._shellLoader.config.application.smartOnFhirConfig;

      this._oauthService.loadDiscoveryDocument(discoveryDocumentUrl).then(() => {
        // the endpoint we discover from the discovery document
        const tokenEndpoint = this._oauthService.tokenEndpoint;

        const body = new URLSearchParams();
        body.set('code', params['code']);
        body.set('code_verifier', localStorage.getItem('PKCE_verifier'));
        body.set('grant_type', 'authorization_code');
        body.set('client_id', clientId);
        body.set('redirect_uri', redirectUri);

        this.http
          .post(tokenEndpoint, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .subscribe((data) => {
            this._configBasedStorage.setItem('token', data['access_token']);
            this._configBasedStorage.setItem('access_token', data['access_token']);
            this._configBasedStorage.setItem('expires_in', data['expires_in']);

            // We can perform a simple check to determine if the 'access_token' is a JWT.
            var isJWT = data['access_token'].split('.').length === 3;
            if (isJWT) {
              const base64Url = data['access_token'].split('.')[1];

              const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
              const jsonPayload = decodeURIComponent(
                window
                  .atob(base64)
                  .split('')
                  .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                  })
                  .join('')
              );
              const decoded = JSON.parse(jsonPayload);
            }

            if (data['refresh_token']) {
              this._configBasedStorage.setItem('refresh', data['refresh_token']);
              this._configBasedStorage.setItem('refresh_token', data['refresh_token']);
              this._smartOnFhirService.refreshToken$.next(data['refresh_token']);
              this._oauthService.setupAutomaticSilentRefresh();
            }
            this._smartOnFhirService.accessToken$.next(data['access_token']);
            this._router.navigate([this._appKey]);
          });
      });
    });
  }
}
