<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div>
  <app-module-outlet
    *ngIf="shellLoader.moduleConfiguration"
    [moduleConfig]="shellLoader.moduleConfiguration"
  ></app-module-outlet>
</div>

<div class="html-inject"></div>
