/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// models
import {
  Accordion,
  Button,
  Calendar,
  CarechartAccordion,
  Checkbox,
  Dropdown,
  DynamicText,
  Fieldset,
  FormElement,
  InputMask,
  InputSwitch,
  InputText,
  InputTextarea,
  InvisibleContainer,
  MarkdownText,
  MultiSelect,
  RadioButton,
  RepeatableContainer,
  RootContainer,
  Spacing,
  StaticImage,
  StaticText,
} from '@shared/models';

// services
import { BuilderService } from '@root/services/builder.service';
import { ColoredSelectButton } from '@shared/models/colored-select-button';

import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  draggableElementIds: string[];

  @Input() mode: 'select' | 'drag' = 'drag';
  @Output() componentSelected = new EventEmitter<any>();

  palette: Array<{
    section: string;
    tools: Array<{ name: string; iconCode?: string; tool: FormElement }>;
  }>;

  constructor(public builder: BuilderService) {
    this.palette = [
      {
        section: 'Common',
        tools: [
          {
            name: 'Text Field',
            iconCode: 'grip-lines',
            tool: new InputText({
              label: 'New Input Text',
              name: 'inputtext',
              colspan: 12,
              hidden: false,
              inputType: 'text',
              placeholder: 'Create a placeholder here',
            }),
          },
          {
            name: 'Button',
            iconCode: 'stop',
            tool: new Button({
              name: 'button',
              label: 'New Button',
              type: 'button',
              colspan: 2,
            }),
          },
          {
            name: 'Checkbox',
            iconCode: 'check',
            tool: new Checkbox({
              name: 'checkbox',
              label: '',
              binary: true,
              options: [{ label: '', value: 'true' }],
              horizontal: false,
              colspan: 2,
              colspanPerItem: 1,
            }),
          },
          {
            name: 'Dropdown menu',
            iconCode: 'caret-down',
            tool: new Dropdown({
              name: 'dropdown',
              label: 'New Dropdown',
              filter: true,
              filterBy: 'label',
              placeholder: 'Select an option',
              options: [
                { label: 'Option 1', value: '1' },
                { label: 'Option 2', value: '2' },
              ],
              horizontal: null,
              colspan: 12,
            }),
          },
          {
            name: 'Container',
            iconCode: 'expand',
            tool: new InvisibleContainer({
              name: 'container',
              colspan: 12,
            }),
          },
          {
            name: 'Spacing',
            iconCode: 'arrows-alt-v',
            tool: new Spacing({
              colspan: 12,
              name: 'spacing',
              height: '60px',
            }),
          },
        ],
      },
      {
        section: 'Text Elements',
        tools: [
          {
            name: 'Text Field',
            iconCode: 'grip-lines',
            tool: new InputText({
              label: 'New Input Text',
              name: 'inputtext',
              colspan: 12,
              hidden: false,
              inputType: 'text',
              placeholder: 'Create a placeholder here',
            }),
          },
          {
            name: 'Mask',
            iconCode: 'mask',
            tool: new InputMask({
              name: 'inputmask',
              label: 'New Input Mask',
              colspan: 12,
              mask: '',
              autoClear: false,
              slotChar: '',
              placeholder: 'Create a placeholder here',
            }),
          },
          {
            name: 'Date Time Picker',
            iconCode: 'calendar-plus',
            tool: new Calendar({
              name: 'calendar',
              label: 'New Calendar',
              colspan: 12,
              minDate: null,
              maxDate: null,
              showButtonBar: false,
              selectionMode: 'single',
              dateFormat: 'yy-mm-dd',
            }),
          },
          {
            name: 'Text Area',
            iconCode: 'align-justify',
            tool: new InputTextarea({
              name: 'inputtextarea',
              label: 'New Text Area',
              colspan: 12,
              cols: 20,
              rows: 2,
              resizable: true,
              autoResize: false,
            }),
          },
          {
            name: 'Dynamic Text',
            iconCode: 'stream',
            tool: new DynamicText({
              name: 'dynamictext',
              type: 'h3',
              label: 'New Dynamic Text',
              colspan: 12,
            }),
          },
        ],
      },
      {
        section: 'Selection Elements',
        tools: [
          {
            name: 'Input Switch',
            iconCode: 'toggle-on',
            tool: new InputSwitch({
              label: 'New Input Switch',
              name: 'switch',
              colspan: 12,
            }),
          },
          {
            name: 'Checkbox',
            iconCode: 'check',
            tool: new Checkbox({
              name: 'checkbox',
              label: '',
              binary: true,
              options: [{ label: '', value: 'true' }],
              horizontal: false,
              colspan: 2,
              colspanPerItem: 1,
            }),
          },
          {
            name: 'Select boxes',
            iconCode: 'check',
            tool: new Checkbox({
              name: 'checkbox',
              label: 'New Checkbox',
              options: [
                { label: 'Option 1', value: '1' },
                { label: 'Option 2', value: '2' },
              ],
              horizontal: false,
              colspan: 12,
              colspanPerItem: 1,
            }),
          },
          {
            name: 'Radio Buttons',
            iconCode: 'dot-circle',
            tool: new RadioButton({
              name: 'radiobutton',
              label: 'New Radio',
              options: [
                { label: 'Option 1', value: '1' },
                { label: 'Option 2', value: '2' },
              ],
              horizontal: false,
              colspan: 12,
              colspanPerItem: 1,
            }),
          },
          {
            name: 'Colored Selects',
            iconCode: 'dot-circle',
            tool: new ColoredSelectButton({
              name: 'radiobutton',
              label: 'New Colored Selects',
              options: [
                { label: 'Option 1', value: '1', color: 'green' },
                { label: 'Option 2', value: '2', color: 'yellow' },
                { label: 'Option 3', value: '3', color: 'red' },
              ],
              horizontal: false,
              colspan: 12,
              colspanPerItem: 4,
            }),
          },
          {
            name: 'Yes/No',
            iconCode: 'dot-circle',
            tool: new ColoredSelectButton({
              name: 'radiobutton',
              label: 'New Yes/No',
              options: [
                { label: 'Yes', value: true, color: 'blue' },
                { label: 'No', value: false, color: 'blue' },
              ],
              horizontal: false,
              colspan: 12,
              colspanPerItem: 3,
            }),
          },
          {
            name: 'Dropdown menu',
            iconCode: 'caret-down',
            tool: new Dropdown({
              name: 'dropdown',
              label: 'New Dropdown',
              filter: true,
              filterBy: 'label',
              placeholder: 'Select an option',
              options: [
                { label: 'Option 1', value: '1' },
                { label: 'Option 2', value: '2' },
              ],
              horizontal: null,
              colspan: 12,
            }),
          },
          {
            name: 'MultiSelect',
            iconCode: 'tasks',
            tool: new MultiSelect({
              name: 'multiselect',
              label: 'New MultiSelect',
              filter: true,
              filterBy: 'label',
              options: [
                { label: 'Option 1', value: '1' },
                { label: 'Option 2', value: '2' },
              ],
              horizontal: null,
              colspan: 12,
            }),
          },
        ],
      },
      {
        section: 'Layout Elements',
        tools: [
          {
            name: 'Button',
            iconCode: 'stop',
            tool: new Button({
              name: 'button',
              label: 'New Button',
              type: 'button',
              colspan: 2,
            }),
          },
          {
            name: 'Container',
            iconCode: 'expand',
            tool: new InvisibleContainer({
              name: 'container',
              colspan: 12,
            }),
          },
          {
            name: 'Spacing',
            iconCode: 'arrows-alt-v',
            tool: new Spacing({
              colspan: 12,
              name: 'spacing',
              height: '60px',
            }),
          },
          {
            name: 'Static Text',
            iconCode: 'font',
            tool: new StaticText({
              name: 'textlayout',
              content: 'Example text',
              type: 'h1',
              colspan: 12,
            }),
          },
          {
            name: 'Markdown Text',
            iconCode: 'font',
            tool: new MarkdownText({
              name: 'markdowntext',
              markdownText: '## Example Header\nExample Text',
              colspan: 12,
            }),
          },
          {
            name: 'Static Image',
            iconCode: 'image',
            tool: new StaticImage({
              name: 'staticimage',
              remoteUrl: 'https://goverto.com/images/verto-new.png',
              height: 'auto',
              width: '300px',
              colspan: 12,
            }),
          },
          {
            name: 'Accordion',
            iconCode: 'window-maximize',
            tool: new Accordion({
              name: 'accordion',
              header: 'New Accordion',
              children: [],
              lazyLoad: false,
              defaultOpen: false,
              colspan: 12,
            }),
          },
          {
            name: 'Button Toggle',
            iconCode: 'caret-square-down',
            tool: new Fieldset({
              name: 'fieldset',
              colspan: 12,
              legend: 'New Fieldset',
              toggleable: true,
              collapsed: false,
            }),
          },
          {
            name: 'Repeatable Container',
            iconCode: 'plus-square',
            tool: new RepeatableContainer({
              name: 'repeatablecontainer',
              colspan: 12,
              addButtonLabel: 'Add new option',
              minItems: 0,
              maxItems: 10,
            }),
          },
        ],
      },
      {
        section: 'FHIR Elements',
        tools: [
          {
            name: 'Quantity',
            iconCode: 'balance-scale-right',
            tool: new InvisibleContainer({
              colspan: 6,
              name: 'quantity',
              children: [
                new InputText({
                  name: 'quantity',
                  colspan: 6,
                  label: 'Quantity',
                  hidden: false,
                  inputType: 'text',
                }),
                new InputText({
                  name: 'unit',
                  colspan: 6,
                  label: 'Units',
                  hidden: false,
                  inputType: 'text',
                }),
              ],
            }),
          },
          {
            name: 'Period',
            iconCode: 'hourglass-half',
            tool: new InvisibleContainer({
              name: 'period',
              colspan: 12,
              children: [
                new Calendar({
                  name: 'start',
                  colspan: 4,
                  label: 'Start Date',
                  dateFormat: 'yy-mm-dd',
                  minDate: null,
                  maxDate: null,
                  selectionMode: 'single',
                  showButtonBar: false,
                }),
                new Calendar({
                  name: 'end',
                  colspan: 4,
                  label: 'End Date',
                  dateFormat: 'yy-mm-dd',
                  minDate: null,
                  maxDate: null,
                  selectionMode: 'single',
                  showButtonBar: false,
                }),
              ],
            }),
          },
        ],
      },
      {
        section: 'Custom Elements',
        tools: [
          {
            name: 'Carechart Accordion',
            iconCode: 'window-maximize',
            tool: new CarechartAccordion({
              colspan: 12,
              header: 'Carechart Accordion',
              defaultOpen: false,
              lazyLoad: false,
              name: 'carechart_accordion',
              children: [
                new InputSwitch({
                  name: 'carechart_accordion_complete',
                  colspan: 12,
                  required: false,
                  publishActions: [
                    { linkName: 'on_complete', condition: 'NON_EMPTY' },
                    { linkName: 'on_incomplete', condition: 'EMPTY' },
                  ],
                  label: 'Complete Assessment',
                }),
                new MarkdownText({
                  name: 'accordion_complete_text',
                  colspan: 12,
                  required: false,
                  markdownText:
                    'Note: this only minimizes the practice guide, and you can still expand it to review the submitted content before sending the complete assessment.',
                }),
              ],
              subscribeActions: [
                {
                  type: 'SET_ATTRIBUTE_TRUE',
                  linkName: 'on_complete',
                  data: 'isComplete',
                },
                {
                  type: 'SET_ATTRIBUTE_FALSE',
                  linkName: 'on_incomplete',
                  data: 'isComplete',
                },
                {
                  type: 'SET_ATTRIBUTE_TRUE',
                  linkName: 'on_incomplete',
                  data: 'defaultOpen',
                },
                {
                  type: 'SET_ATTRIBUTE_FALSE',
                  linkName: 'on_complete',
                  data: 'defaultOpen',
                },
              ],
            }),
          },
        ],
      },
    ];
    this.onDragOut();
  }

  ngOnInit() {
    this.builder
      .getDraggableElementIds()
      .subscribe((ids) => (this.draggableElementIds = ids));
  }

  onDragOut() {
    this.builder.getRoot().subscribe((root: RootContainer) => {
      this.draggableElementIds = root.getContainerIds();
    });
  }
}

@NgModule({
  imports: [CommonModule, AccordionModule, DragDropModule, ButtonModule],
  exports: [ToolbarComponent],
  declarations: [ToolbarComponent],
  providers: [],
})
export class ToolbarModule {}
