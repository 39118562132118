/* Copyright 2023 (Unpublished) Verto Inc. */

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeDate',
  standalone: true,
})
export class RelativeDatePipe implements PipeTransform {
  /**
   * Default returns: Today, Tomorrow, Day of week, Month day, Year
   */
  transform(value: Date, precision: 'week' | 'day' | 'month' | 'year' = 'week'): string {
    const dist = moment(value).calendar();
    if (precision === 'week') {
      // If the date is today, tomorrow, or yesterday, return that
      if (!dist.includes('/')) {
        return dist.split(' ')[0];
      }

      // Get the number of weeks between the date and today
      const startOfWeek = moment(value).startOf('week');
      const weeks = startOfWeek.diff(moment().startOf('week'), 'weeks');

      return `In ${weeks} weeks`;
    }
  }
}
