/* Copyright 2023 (Unpublished) Verto Inc. */

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormData } from 'engage-common';
import { valOrEval } from '../mood-utils/val-or-eval';

export interface MoodRadioButton {
  label: string;
  value: any;
}

@Component({
  selector: 'mood-radio-group',
  templateUrl: './mood-radio-group.component.html',
  styleUrls: ['./mood-radio-group.component.scss'],
})
export class MoodRadioGroupComponent {
  @Input() radioButtons: MoodRadioButton[] = [];
  @Input() set selected(value: string) {
    const foundButton = this.radios.find(
      (radioButton) => radioButton.nativeElement.value === value
    );

    if (!foundButton) {
      return;
    }

    this.radios.forEach((radio: ElementRef) => {
      radio.nativeElement.checked = false;
    });

    foundButton.nativeElement.checked = true;
  }

  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;

  @Output() onChange = new EventEmitter<any>();
  @ViewChildren('radio') radios: QueryList<ElementRef>;

  radioLabel(radioButton): string {
    if (this.form) {
      return valOrEval(radioButton.label);
    }

    return radioButton.label;
  }

  handleClick(event: Event, radioButton: MoodRadioButton) {
    const element = event.target;
    const checked = (element as any).checked;

    if (checked) {
      // TODO(aleksanderbodurri): figure out why this doesn't automatically update in the subscription
      this.form.controls[this.formData.controlName].setValue(radioButton.value);
      this.formData.currentValue = radioButton.value;
    } else {
      // TODO(aleksanderbodurri): figure out why this doesn't automatically update in the subscription
      this.form.controls[this.formData.controlName].setValue('');
      this.formData.currentValue = '';
    }

    this.radios.forEach((radio: ElementRef) => {
      if (radio.nativeElement !== element) {
        radio.nativeElement.checked = false;
      }
    });

    this.onChange.emit({ checked, element, radioButton });
  }
}
