<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<form [formGroup]="form">
  <div [ngClass]="wrapperClasses">
    <div
      *ngFor="let unit of currentFrame"
      [ngClass]="classes(unit)"
      [style.margin]="
        unit.margin ? unit.margin.join(' ') : unit.type === MoodRendererUnitType.PARAGRAPH ? '0' : '0 0 0.5em 0'
      "
      [style.padding]="unit.padding ? unit.padding.join(' ') : '0'"
      class="render-unit"
    >
      <ng-container [ngSwitch]="unit.type">
        <ng-container *ngSwitchCase="MoodRendererUnitType.INPUT">
          <mood-input
            [label]="unit.label"
            [disabled]="unit.disabled"
            [form]="form"
            [formData]="unit.formData"
            [helpText]="unit.helpText"
          ></mood-input>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.TEXTAREA">
          <mood-textarea
            [label]="unit.label"
            [maxLength]="unit.maxLength"
            [form]="form"
            [formData]="unit.formData"
            [helpText]="unit.helpText"
          ></mood-textarea>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.ACCORDION">
          <mood-accordion [header]="unit.header" [expanded]="unit.expanded">
            <mood-renderer
              [moodFrame]="unit.content"
              (formUpdated)="this.nestedFormUpdated.emit($event)"
            ></mood-renderer>
          </mood-accordion>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.RADIO_GROUP">
          <mood-radio-group [form]="form" [formData]="unit.formData" (onChange)="unit.change && unit.change($event)">
          </mood-radio-group>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.CHECKBOX">
          <mood-checkbox [form]="form" [formData]="unit.formData"> </mood-checkbox>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.MULTI_CHECKBOX">
          <mood-multi-checkbox
            [form]="form"
            [formData]="unit.formData"
            (onChange)="unit.change($event)"
          ></mood-multi-checkbox>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.PARAGRAPH">
          <mood-paragraph [content]="unit.html"></mood-paragraph>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.BUTTON">
          <mood-button [buttonClasses]="unit.buttonClasses" [disabled]="unit.disabled" (onClick)="unit.click($event)">
            {{ valOrEval(unit.text) }}
          </mood-button>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.BUTTON_GROUP">
          <ng-container *ngFor="let button of unit.buttons">
            <mood-button
              [buttonClasses]="button.buttonClasses"
              [disabled]="button.disabled"
              (onClick)="button.click($event)"
            >
              {{ valOrEval(button.text) }}
            </mood-button>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.DROPDOWN">
          <mood-dropdown [form]="form" [formData]="unit.formData" [label]="unit.label"> </mood-dropdown>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.HEADER">
          <mood-header [label]="unit.label"></mood-header>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.CAPTCHA">
          <mood-captcha (resolved)="unit.onResolved($event)" [siteKey]="unit.siteKey"></mood-captcha>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.MULTI_SELECT">
          <mood-multi-select [formData]="unit.formData" [form]="form" [label]="unit.label"></mood-multi-select>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.DATE_PICKER">
          <mood-datepicker [form]="form" [formData]="unit.formData"></mood-datepicker>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.CODE_DISPLAY">
          <mood-code-display [label]="unit.label" [value]="unit.value"></mood-code-display>
        </ng-container>

        <ng-container *ngSwitchCase="MoodRendererUnitType.BLOCK">
          <div [innerHtml]="unit.html"></div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
