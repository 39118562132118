/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SmartOnFhirService {
  refreshToken$ = new BehaviorSubject<string | null>(null);
  accessToken$ = new BehaviorSubject<string | null>(null);
  decodedAccessToken$ = new BehaviorSubject<object | null>(null);
}
