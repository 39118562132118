/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Directive, OnInit, OnDestroy, Input, EnvironmentInjector } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Directive({
  selector: 'named-outlet',
  exportAs: 'outlet',
})
export class NamedOutletDirective implements OnInit, OnDestroy {
  public outlet: RouterOutlet;
  @Input() public name: string;

  constructor(private environmentInjector: EnvironmentInjector) {}

  ngOnInit() {
    this.environmentInjector.runInContext(() => {
      // RouterOutlet contains some `inject` calls, so we initialize it inside the context of our environment injector
      this.outlet = new RouterOutlet();

      // The only property that RouterOutlet does not set with inject is name.
      // Unfortunately Angular has started typing this as a private field in v15.
      // We'll need to bypass this to get the same behaviour.
      this.outlet.name = this.name;
      this.outlet.ngOnInit();
    });
  }

  ngOnDestroy() {
    if (this.outlet) {
      this.outlet.ngOnDestroy();
    }
  }
}
