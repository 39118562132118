/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Injectable } from '@angular/core';

// components
import * as components from '@shared/components';

// models
import * as models from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class FormElementFactory {
  readonly templateMapper: {
    [element: string]: { component: any; model: any };
  } = this.getMapping();

  getComponent(elementString: string) {
    return this.templateMapper[elementString].component;
  }

  getModel(model: string) {
    return this.templateMapper[model].model;
  }

  private getMapping() {
    const keys = Object.getOwnPropertyNames(models);
    const output = {};
    keys.forEach((key) => {
      output[key] = {
        component: components[key + 'Component'],
        model: models[key],
      };
    });
    return output;
  }
}
