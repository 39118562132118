<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<ng-container *ngIf="!form; else withinForm">
  <label
    class="container-check"
    tabindex="0"
    (keydown.enter)="checkbox.click()"
    [ngClass]="{ active: checkbox.checked }"
  >
    <div *ngIf="htmlLabel" [innerHTML]="htmlLabel"></div>
    {{ checkboxLabel }}
    <input type="checkbox" [value]="value" class="required" #checkbox (change)="change()" tabindex="-1" />
    <span class="checkmark"></span>
  </label>
</ng-container>

<ng-template #withinForm>
  <label
    [formGroup]="form"
    class="container-check"
    tabindex="0"
    (keydown.enter)="checkbox.click()"
    [ngClass]="{ active: form.controls[formData.controlName].value === formData.options[0].value }"
  >
    <div *ngIf="htmlLabel" [innerHTML]="htmlLabel"></div>
    {{ checkboxLabel }}
    <input
      tabindex="-1"
      #checkbox
      [formControlName]="formData.controlName"
      [type]="formData.controlType"
      [name]="formData.controlName"
      [checked]="form.controls[formData.controlName].value === formData.options[0].value"
      [value]="formData.options[0].value"
      [required]="formData.validators.required"
      [pattern]="formData.validators.pattern"
      (click)="handleClick($event, formData.options[0])"
    />
    <span class="checkmark"></span>
  </label>
</ng-template>
