/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { StaticText } from '@shared/models';

@Component({
  selector: 'app-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss'],
})
export class StaticTextComponent extends FormElementComponent {
  item: StaticText;
}
