/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { DynamicText } from '@shared/models';

@Component({
  selector: 'app-dynamic-text',
  templateUrl: './dynamic-text.component.html',
  styleUrls: ['./dynamic-text.component.scss'],
})
export class DynamicTextComponent extends FormElementComponent {
  item: DynamicText;

  displayValue(): string {
    return this.buildable ? 'Example Text' : this.item.formControl.value;
  }
}
