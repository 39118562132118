/* Copyright 2023 (Unpublished) Verto Inc. */

export class FHIRDate {
  constructor(
    public year,
    public month,
    public day,
    public hour,
    public minute,
    public second,
    public zone
  ) {}

  static fromFHIRDatetimeString(datetime: string) {
    try {
      const [date, timezone] = datetime.split('T');
      const [year, month, day] = date.split('-');
      const time = timezone.replace('-', '|').replace('+', '|').split('|')[0];
      const zone = timezone.replace(time, '');
      const [hour, minute, second] = time.split(':');
      return new FHIRDate(year, month, day, hour, minute, second, zone);
    } catch (_e) {
      return;
    }
  }
}
