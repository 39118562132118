/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { FileUpload } from '@shared/models';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends FormElementComponent {
  item: FileUpload;
}
