/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { AbstractControl } from '@angular/forms';
import { FormElementImpl } from '@shared/models/form-element-impl';

export class FormLayout extends FormElementImpl {
  getContainerIds(): string[] {
    return [];
  }

  toFormControl(): AbstractControl {
    return null;
  }
}
