/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Injectable } from '@angular/core';

// services
import { FormElementFactory } from '@root/services/form-element.factory';

// models
import { FormContainer } from '@shared/models';
import { FormElement } from '@shared/models/form-element';
import { FormElementImpl } from '@shared/models/form-element-impl';

@Injectable({
  providedIn: 'root',
})
export class FormParser {
  constructor(private formElementFactory: FormElementFactory) {}

  parseComponentTree(treeElement: object): FormElement {
    const element = this.getElementInstance(treeElement);
    // if there are children, parse them
    if (
      treeElement.hasOwnProperty('children') &&
      Array.isArray(treeElement['children'])
    ) {
      const children = treeElement['children'];
      const elementChildren = [];
      // for-index is more efficient than map while parsing trees
      for (let i = 0; i < children.length; i++) {
        const child = this.parseComponentTree(children[i]);
        child.parent = element as FormContainer;
        elementChildren.push(child);
      }
      (element as FormContainer).children = elementChildren;
    }

    return element;
  }

  private getElementInstance(treeElement: object): FormElementImpl {
    // attempt to get the class of current element
    const elementClass = this.formElementFactory.getModel(
      treeElement['elementType']
    );
    // instantiate element
    return new elementClass(treeElement);
  }
}
