/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Models
import { MultiSelect } from '@shared/models';
import { ListOption } from '@shared/models/list-form-component';

@Component({
  selector: 'app-patch-autocomplete',
  templateUrl: './patch-autocomplete.component.html',
  styleUrls: ['./patch-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatchAutocompleteComponent),
      multi: true,
    },
  ],
})
export class PatchAutocompleteComponent implements ControlValueAccessor {
  constructor() {}
  /**
   * PrimeNG's Autocomplete does not work in a way we want.
   * This component patches the functionality and populates the FormControl properly.
   *
   * The PrimeNG implementation will output Array<{value: any, label: any}> instead of any[] representing a list of values.
   * getLabelFromValue() will attempt to get the correct values.
   *
   * https://www.primefaces.org/primeng/#/autocomplete
   */

  @Input() item: MultiSelect;
  @Input() options: ListOption[];
  @Output() onChangeCallback: EventEmitter<void> = new EventEmitter();
  _value: any[];

  disabled: boolean;

  onTouched: CallableFunction;
  onChange: CallableFunction;

  results: any[];

  filterResults(event): void {
    this.results = this.options.filter(
      (option: { label: string; value: any }) => {
        return (
          option.label.toLowerCase().indexOf(event.query.toLowerCase()) === 0
        );
      }
    );
  }

  set value(val) {
    this._value = val;
    const outputVal = val ? val.map((v) => v.value) : val;
    if (this.onChange) {
      this.onChange(outputVal);
    }
    if (this.onTouched) {
      this.onTouched(outputVal);
    }
    if (this._value !== undefined) {
      this.onChangeCallback.next();
    }
  }

  get value(): any[] {
    return this._value;
  }

  registerOnChange(fn: CallableFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: CallableFunction): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getLabelFromValue(value: any): string {
    const candidates = this.options.filter((option) => option.value === value);
    return candidates.length > 0 ? candidates[0].label : value;
  }

  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.item.allowFreeText) {
      const tokenInput = event.srcElement as any;
      if (tokenInput.value) {
        this.value = this.value || [];
        this.value = this.value.concat([
          { label: tokenInput.value, value: tokenInput.value },
        ]);
        tokenInput.value = '';
      }
    }
  }

  writeValue(val: Array<{ label: string; value: any }>): void {
    if (!val) {
      this.value = undefined;
      return;
    }
    this.value = val.map((v) => {
      return { label: this.getLabelFromValue(v), value: v };
    });
  }
}
