<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<ng-container *ngIf="hasValidators && item.formControl.errors && (controlInvalid || formState === 'error')">
  <div *ngIf="item.formControl.errors.required; else customErrors" class="help-block">
    <span>{{ item.requiredErrorText || 'This field is required.' }}</span>
  </div>
  <ng-template #customErrors>
    <div *ngFor="let validator of item.validators" class="help-block">
      <span *ngIf="item.formControl.errors[validator.name]">{{ validator.message }}</span>
    </div>
  </ng-template>
</ng-container>
