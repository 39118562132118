<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="mood-dropdown">
  <p-dropdown *ngIf="!form; else withinForm" [options]="options" (onChange)="valueChange($event)"></p-dropdown>

  <ng-template #withinForm>
    {{ inputLabel }}
    <ng-container [formGroup]="form">
      <p-dropdown
        [required]="formData.validators.required"
        [placeholder]="formData.placeholder"
        [formControlName]="formData.controlName"
        [options]="formData.options"
        (onChange)="valueChange($event)"
        appendTo="body"
      ></p-dropdown>
    </ng-container>
  </ng-template>
</div>
