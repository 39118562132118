<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<ng-container *ngIf="buildable; else renderOnly">
  <h2 class="form-header text-center build-root" (click)="onComponentClick(item, $event)">
    {{ item.formBuilderName || 'Select a name' }}
  </h2>
  <div
    cdkDropList
    [id]="item.id"
    (cdkDropListDropped)="onDragDrop($event)"
    [cdkDropListConnectedTo]="draggableElementIds"
    [cdkDropListData]="item"
    class="item-dropzone row"
  >
    <app-dynamic-component
      cdkDrag
      *ngFor="let child of item.children"
      [item]="child"
      [cdkDragData]="child"
      [buildable]="buildable"
      (click)="onComponentClick(child, $event)"
      class="col-md-{{ child.getColSpan() }} col-sm-{{ child.getColSpan() }} col-lg-{{ child.getColSpan() }}"
    >
    </app-dynamic-component>

    <ng-container *ngIf="item.children.length === 0">
      <span class="p-col-12 p-col-align-center text-center">Drag something to me</span>
    </ng-container>

    <div class="select-component">
      <p-overlayPanel #op>
        <ng-template pTemplate>
          <app-toolbar (componentSelected)="componentSelected($event, op)" mode="select"></app-toolbar>
        </ng-template>
      </p-overlayPanel>
      <button type="text" pButton label="+" class="create-cmp" (click)="op.toggle($event)"></button>
    </div>
  </div>
</ng-container>

<ng-template #renderOnly>
  <div class="p-grid view-root w-100">
    <app-dynamic-component
      *ngFor="let child of item.children"
      class="p-col-{{ child.getColSpan() }}"
      [item]="child"
      [ngClass]="{ hidden: child.hidden }"
    >
    </app-dynamic-component>
  </div>
</ng-template>
