/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Component
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { InputTextarea } from '@shared/models';
@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
})
export class InputTextareaComponent extends FormElementComponent {
  item: InputTextarea;
}
