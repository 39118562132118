/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// RxJS
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Services
import { IframeService } from '@root/services/iframe.service';

// Models
import { ListOption } from '@shared/models/list-form-component';

@Injectable({
  providedIn: 'root',
})
export class FormListOptionsService {
  constructor(private http: HttpClient, private iframeService: IframeService) {}

  getOptions(
    source?: string,
    bearerTokenKey?: string
  ): Observable<ListOption[]> {
    return source ? this.getOptionsObservable(source, bearerTokenKey) : of([]);
  }

  getEnvironmentOptions(envVar?: string): ListOption[] {
    const env = this.iframeService.environment;
    const regex = /^#\{\w+\}$/g;
    let options = [];
    envVar = envVar.trim();

    // Check that the Environment Variable syntax is correct
    if (regex.test(envVar)) {
      const key = this.retrieveEnvKey(envVar);
      if (env) {
        options = env[key] || [];
      }
    } else {
      console.error('Invalid syntax for Environment Options variable');
    }
    return options;
  }

  private getOptionsObservable(
    url: string,
    bearerTokenKey?: string
  ): Observable<ListOption[]> {
    const regex = /#\{\w+\}/g;
    url = this.replaceEnvVariables(url);

    if (!!bearerTokenKey) {
      bearerTokenKey = this.replaceEnvVariables(bearerTokenKey);
    }

    // Environment did not have the required keys, cannot make request
    if (regex.test(`${url} ${bearerTokenKey}`)) {
      console.error(
        'ERROR - Missing environment variables. Unable to make HTTP request'
      );
      return new Observable<ListOption[]>();
    }

    const options = {};
    if (!!bearerTokenKey) {
      options['headers'] = {
        Authorization: `Bearer ${bearerTokenKey}`,
      };
    }

    return this.http.get<ListOption[]>(url, options).pipe(
      debounceTime(500),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  private replaceEnvVariables(text: string): string {
    const env = this.iframeService.environment;
    const regex = /#\{\w+\}/g;
    const envVariables = text.match(regex);

    if (env && envVariables) {
      envVariables.forEach((envVar: string) => {
        const envKey = this.retrieveEnvKey(envVar);
        const envValue = env[envKey];
        if (envValue) {
          text = text.replace(envVar, envValue);
        }
      });
    }

    return text;
  }

  private retrieveEnvKey(envVar: string): string {
    return envVar.replace(/#|{|}/g, '');
  }
}
