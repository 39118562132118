/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormData } from 'engage-common';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-checkbox',
  templateUrl: './mood-checkbox.component.html',
  styleUrls: ['./mood-checkbox.component.scss'],
})
export class MoodCheckboxComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() set checked(checked: boolean) {
    setTimeout(() => {
      if (this.checkbox) {
        this.checkbox.nativeElement.checked = checked;
      }
    });
  }

  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;

  @ViewChild('checkbox') checkbox: ElementRef;

  @Output() onChange = new EventEmitter<any>();

  get checkboxLabel(): string {
    if (this.form) {
      return valOrEval(this.formData.options[0].label);
    }

    return this.label;
  }

  get htmlLabel(): string {
    if (this.form) {
      return valOrEval(this.formData.options[0].html);
    }
  }

  change() {
    const checked = this.checkbox.nativeElement.checked;
    const value = checked ? this.value : undefined;
    this.onChange.emit({ checked, value });
  }

  handleClick(event: MouseEvent, checkbox) {
    const element = event.target;
    const checked = (element as any).checked;

    if (checked) {
      // TODO(aleksanderbodurri): figure out why this doesn't automatically update in the subscription
      this.form.controls[this.formData.controlName].setValue(checkbox.value);
      this.formData.currentValue = checkbox.value;
    } else {
      // TODO(aleksanderbodurri): figure out why this doesn't automatically update in the subscription
      this.form.controls[this.formData.controlName].setValue('');
      this.formData.currentValue = '';
    }

    this.onChange.emit({ checked, element, checkbox });
  }
}
