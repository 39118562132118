/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input, OnInit } from '@angular/core';
import { MoodState } from '.';

/*
  Context part of the GOF State pattern. Behaviour is determined entirely by state input
  For all intents and purposes this is a simple wrapper component around mood renderer
 */
@Component({
  selector: 'mood-context',
  templateUrl: './mood-context.component.html',
  styleUrls: ['./mood-context.component.scss'],
})
export class MoodContextComponent {
  @Input() state: MoodState;
}
