<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<p-fileUpload
  mode="basic"
  [name]="item.name"
  [accept]="item.accept"
  (uploadHandler)="onBasicUpload($event)"
  [disabled]="disabled"
  [customUpload]="true"
></p-fileUpload>
