/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Interfaces
import { ApplicationConfig } from './interfaces/shell-config';

// rxjs
import { Observable, NEVER } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MicrofrontendConfigurationService {
  constructor(private _http: HttpClient) {}

  lookupConfig(key: string): Observable<ApplicationConfig> {
    if (!key) {
      console.error('No key provided to lookupConfig. Aborting.');
      return NEVER;
    }
    return this._http.get(
      `${environment.backendUrl}/api/public/application/${key}`
    ) as Observable<ApplicationConfig>;
  }
}
