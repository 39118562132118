/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component } from '@angular/core';

// Components
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';

// Models
import { RadioButton } from '@shared/models/radio-button';

@Component({
  selector: 'app-checkbox',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent extends ListFormComponentComponent {
  item: RadioButton;
}
