/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// components
import {
  AccordionComponent,
  ButtonComponent,
  CalendarComponent,
  CarechartAccordionComponent,
  CheckboxComponent,
  ColoredSelectButtonComponent,
  DropdownComponent,
  DynamicTextComponent,
  FieldsetComponent,
  FileUploadComponent,
  InputMaskComponent,
  InputSwitchComponent,
  InputTextareaComponent,
  InputTextComponent,
  InvisibleContainerComponent,
  MarkdownTextComponent,
  MultiSelectComponent,
  RadioButtonComponent,
  RepeatableContainerComponent,
  RootContainerComponent,
  SpacingComponent,
  StaticImageComponent,
  StaticTextComponent,
} from '@shared/components';

import { DynamicComponentComponent } from '@shared/components/dynamic-component/dynamic-component.component';
import { FormElementComponent } from '@shared/components/form-element/form-element.component';
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';
import { Base64FileUploadComponent } from '@shared/custom-form-controls/base64-file-upload/base64-file-upload.component';
import { PatchCalendarComponent } from '@shared/custom-form-controls/patch-calendar/patch-calendar.component';
import { PatchInputMaskComponent } from '@shared/custom-form-controls/patch-input-mask/patch-input-mask.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { FormValidatorsComponent } from './components/form-validators/form-validators.component';
import { RepeatableContainerControlComponent } from './components/repeatable-container/repeatable-container-control/repeatable-container-control.component';
import { PatchAutocompleteComponent } from './custom-form-controls/patch-autocomplete/patch-autocomplete.component';

// primeng
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';

import { TextFieldModule } from '@angular/cdk/text-field';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from '@root/toolbar/toolbar.component';

@NgModule({
  declarations: [
    ButtonComponent,
    DynamicComponentComponent,
    FormElementComponent,
    InputSwitchComponent,
    InputTextComponent,
    RootContainerComponent,
    SpacingComponent,
    InvisibleContainerComponent,
    DropdownComponent,
    InputTextareaComponent,
    CheckboxComponent,
    StaticTextComponent,
    AccordionComponent,
    FormContainerComponent,
    InputMaskComponent,
    FieldsetComponent,
    RadioButtonComponent,
    CalendarComponent,
    FormValidatorsComponent,
    PatchCalendarComponent,
    DynamicTextComponent,
    RepeatableContainerControlComponent,
    RepeatableContainerComponent,
    PatchInputMaskComponent,
    ListFormComponentComponent,
    StaticImageComponent,
    MultiSelectComponent,
    ColoredSelectButtonComponent,
    PatchAutocompleteComponent,
    MarkdownTextComponent,
    FileUploadComponent,
    Base64FileUploadComponent,
    CarechartAccordionComponent,
  ],
  imports: [
    ToolbarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    InputSwitchModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    DropdownModule,
    CheckboxModule,
    AccordionModule,
    KeyFilterModule,
    InputMaskModule,
    FieldsetModule,
    RadioButtonModule,
    ScrollPanelModule,
    CalendarModule,
    MultiSelectModule,
    AutoCompleteModule,
    FileUploadModule,
    TextFieldModule,
    OverlayPanelModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    InputSwitchModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    DropdownModule,
    ScrollPanelModule,
    ButtonComponent,
    AccordionModule,
    AutoCompleteModule,
    FileUploadModule,
    DynamicComponentComponent,
    FormElementComponent,
    InputSwitchComponent,
    InputTextComponent,
    RootContainerComponent,
    SpacingComponent,
    InvisibleContainerComponent,
    CheckboxComponent,
    DropdownComponent,
    AccordionComponent,
    RadioButtonComponent,
    PatchCalendarComponent,
    RepeatableContainerControlComponent,
    DynamicTextComponent,
    ColoredSelectButtonComponent,
    MarkdownTextComponent,
    FileUploadComponent,
    Base64FileUploadComponent,
    CarechartAccordionComponent,
    TextFieldModule,
    OverlayPanelModule,
  ],
})
export class SharedModule {}
