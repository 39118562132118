/* Copyright 2023 (Unpublished) Verto Inc. */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_KEY } from 'engage-common';
import { ShellLoader } from 'projects/shell/src/app/ShellLoader';

// TODO: delete if not being used
@Injectable()
export class MeilisearchService {
  headers = {
    Authorization: `Bearer fbf9ab85616ddaeb9fddb77983d4266c6763c27fe492d259bbf2447b6b2df4a0`,
  };

  baseUrl = 'https://app-vertoms-dev-001.azurewebsites.net';

  constructor(
    @Inject(APP_KEY) private _appKey: string,
    private shellLoader: ShellLoader,
    private http: HttpClient
  ) {}

  getServiceType(id?: string): Observable<{ hits: any[] }> {
    return this.http.post<{ hits: any[] }>(
      `${this.baseUrl}/indexes/AppointmentType/search`,
      {
        q: `"${id}"`,
        filter: `service_type_id = ${id}`,
        limit: 1,
      },
      { headers: this.headers }
    );
  }

  getLocation(id?: string) {
    return this.http.post(
      `${this.baseUrl}/indexes/AppointmentType/search`,
      {
        q: `"${id}"`,
        filter: `unit_id = ${id}`,
        limit: 1,
      },
      { headers: this.headers }
    );
  }

  getAppointmentTypesByQuery(query: string): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/indexes/AppointmentType/search`,
      {
        q: query,
        limit: 20,
      },
      { headers: this.headers }
    );
  }
}
