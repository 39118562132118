/* Copyright 2023 (Unpublished) Verto Inc. */

// Models
import { FormLayout } from '@shared/models/form-layout';

// Options
import { StaticImageOptions } from '@shared/options';

export class StaticImage extends FormLayout {
  elementTypeName: string = 'StaticImage';

  width: string;
  height: string;

  remoteUrl?: string;
  base64String?: string;

  constructor(options: StaticImageOptions) {
    super(options);
    this.width = options.width;
    this.height = options.height;

    this.remoteUrl = options.remoteUrl;
    this.base64String = options.base64String;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      remoteUrl: this.remoteUrl,
      base64String: this.base64String,
      width: this.width,
      height: this.height,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON([
      {
        elementType: 'InputText',
        name: 'remoteUrl',
        label: 'Remote URL',
        required: false,
        colspan: 12,
      },
      {
        elementType: 'FileUpload',
        name: 'base64String',
        label: 'Upload an image',
        required: false,
        colspan: 12,
      },
      {
        elementType: 'InputText',
        name: 'height',
        label: 'Height',
        required: true,
        colspan: 12,
      },
      {
        elementType: 'InputText',
        name: 'width',
        label: 'Width',
        required: true,
        colspan: 12,
      },
    ]);
  }
}
