/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Component } from '@angular/core';

// components
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';

// models
import { Checkbox } from '@shared/models';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends ListFormComponentComponent {
  item: Checkbox;
}
