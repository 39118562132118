/* Copyright 2023 (Unpublished) Verto Inc. */

import { Inject, Injectable } from '@angular/core';
import { APP_KEY } from 'engage-common';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  constructor(@Inject(APP_KEY) private _appKey: string) {}

  public saveData(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    return localStorage.getItem(key);
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}
