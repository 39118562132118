/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { FormComponent } from '@shared/models/form-component';
import { InputMaskOptions } from '@shared/options';

export class InputMask extends FormComponent {
  elementTypeName: string = 'InputMask';

  placeholder: string;
  mask: string;
  autoClear: boolean;
  slotChar: string;

  constructor(options: InputMaskOptions) {
    super(options);
    this.mask = options.mask;
    this.autoClear = options.autoClear;
    this.slotChar = options.slotChar;
    this.placeholder = options.placeholder;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      mask: this.mask,
      autoClear: this.autoClear,
      slotChar: this.slotChar,
      placeholder: this.placeholder,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'placeholder',
          label: 'Placeholder',
          required: true,
        },
        {
          elementType: 'InputText',
          name: 'mask',
          label: 'Mask',
          required: false,
        },
        {
          elementType: 'InputText',
          name: 'slotChar',
          label: 'Slot Character',
          required: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'autoClear',
          label: 'Auto Clear',
          required: false,
          default: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
