/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
// Shared
import { FormElementImpl } from '@shared/models/form-element-impl';
import { FormValidator } from '@shared/models/validators/form-validator';
import { FormValidatorType } from '@shared/models/validators/form-validator-type.enum';

const moment = (window as any).moment;

export class ValidatorFactory {
  private static phoneRegex: RegExp = /^((\d)*[- ]?)*(\d)*$/;
  private static emailRegex: RegExp =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  constructor() {}

  static generateValidators(element: FormElementImpl): ValidatorFn[] {
    const validators = [];
    // hard check required
    if (element.required) {
      validators.push(Validators.required);
    }
    // add all configured validators
    for (const validator of element.validators) {
      validators.push(this.generateValidator(validator));
    }
    return validators;
  }

  static generateValidator(validator: FormValidator): ValidatorFn {
    let validatorFn: ValidatorFn;
    switch (validator.type) {
      case FormValidatorType.EMAIL:
        validatorFn = this.emailValidator(validator);
        break;
      case FormValidatorType.PHONE:
        validatorFn = this.phoneValidator(validator);
        break;
      case FormValidatorType.REGEX:
        validatorFn = this.regexValidator(validator);
        break;
      case FormValidatorType.TIME_LESS_THAN_TODAY:
        validatorFn = this.timeValidator(validator, 'less');
        break;
      case FormValidatorType.TIME_GREATER_THAN_TODAY:
        validatorFn = this.timeValidator(validator, 'greater');
        break;
      case FormValidatorType.TIME_EQUAL_TO_TODAY:
        validatorFn = this.timeValidator(validator, 'equal');
    }
    return validatorFn;
  }

  private static emailValidator(validator: FormValidator): ValidatorFn {
    // set defaults
    validator.name = 'email';
    validator.message = validator.message || 'Email provided is invalid';
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validEmail =
        this.emailRegex.test(control.value) ||
        control.value === undefined ||
        (control.value || []).length === 0;
      return !validEmail
        ? { email: { value: control.value, message: validator.message } }
        : null;
    };
  }

  private static phoneValidator(validator: FormValidator): ValidatorFn {
    // set defaults
    validator.name = 'phone';
    validator.message = validator.message || 'Phone number provided is invalid';
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validPhone =
        this.phoneRegex.test(control.value) ||
        control.value === undefined ||
        (control.value || []).length === 0;
      return !validPhone
        ? { phone: { value: control.value, message: validator.message } }
        : null;
    };
  }

  private static regexValidator(validator: FormValidator): ValidatorFn {
    // set defaults
    validator.name = validator.name || 'regex';
    validator.message = validator.message || 'Input failed validation';
    return (control: AbstractControl): { [key: string]: any } | null => {
      const regexMatch =
        new RegExp(validator.customValue).test(control.value) ||
        control.value === undefined ||
        (control.value || []).length === 0;
      return !regexMatch
        ? {
            [validator.name]: {
              value: control.value,
              message: validator.message,
            },
          }
        : null;
    };
  }

  private static timeValidator(
    validator: FormValidator,
    type: 'greater' | 'less' | 'equal'
  ): ValidatorFn {
    validator.name = validator.name || 'date-' + type;
    validator.message = validator.message || 'Date is invalid';

    return (control: AbstractControl): { [key: string]: any } | null => {
      let conditionMet;
      const controlVal = control.value;

      // custom condition is formatted as <number> <unit>
      const customValueSplit = validator.customValue.split(' ');
      const number = +customValueSplit[0];
      const unit: any = customValueSplit[1];

      if (isNaN(number) || !controlVal) {
        conditionMet = true;
      } else {
        const today = moment.utc().startOf(unit);
        const momentControlVal = moment.utc(controlVal).startOf(unit);

        if (type === 'greater') {
          conditionMet = momentControlVal.diff(today, unit) > number;
        } else if (type === 'less') {
          conditionMet = momentControlVal.diff(today, unit) < number;
        } else if (type === 'equal') {
          conditionMet = momentControlVal.diff(today, unit) === number;
        }
      }

      // return validator
      return !conditionMet
        ? {
            [validator.name]: {
              value: control.value,
              message: validator.message,
            },
          }
        : null;
    };
  }
}
