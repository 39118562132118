<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="p-fluid">
  <button
    [attr.data-name]="item.name"
    [type]="item.type"
    pButton
    [label]="item.label"
    (click)="emitEvent()"
    ngDefaultControl
  ></button>
</div>
