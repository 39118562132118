/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

// services
import { FormActionService } from '@root/services/form-action.service';
import { FormElementFactory } from '@root/services/form-element.factory';

// shared
import { FormAction } from '@root/models/form-action';
import { FormActionCondition } from '@root/models/form-action-condition.enum';
import { FormElementComponent } from '@shared/components/form-element/form-element.component';
import { FormElementImpl } from '@shared/models/form-element-impl';

@Component({
  selector: 'app-dynamic-component',
  templateUrl: './dynamic-component.component.html',
  styleUrls: ['./dynamic-component.component.scss'],
})
export class DynamicComponentComponent implements OnInit {
  // this holds extra options for the component
  @Input() extraOptions: {
    index?: number;
  };

  @Input() buildable: boolean;

  @Input() item: FormElementImpl;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  private container: ViewContainerRef;

  constructor(
    private fas: FormActionService,
    private formElementFactory: FormElementFactory,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        this.formElementFactory.getComponent(this.item.elementTypeName)
      );
    const viewContainerRef = this.container;
    viewContainerRef.clear();

    const componentInstance = viewContainerRef.createComponent(componentFactory)
      .instance as FormElementComponent;
    componentInstance.item = this.item;
    componentInstance.buildable = this.buildable;

    if (!this.buildable) {
      this.publishActions(this.item, true);
      this.publishActions(this.item);
      this.handleFormActions();
    }
  }

  private handleFormActions(): void {
    if (this.item.subscribeActions.length > 0) {
      // get all matching actions to items subscribers, handling each one
      this.fas
        .filterActions(this.item.subscribeActions, this.extraOptions)
        .subscribe((actions: FormAction[]) => {
          for (const action of actions) {
            this.modifyAction(action, this.extraOptions);
            this.item.handleAction(action, this.fas.globalDisabled);
          }
        });
    }

    if (this.item.publishActions.length > 0) {
      // on value changes, evaluate every action on the target, emit actions for those whose conditions are satisfied
      this.item.formControl.valueChanges.subscribe(() => {
        this.publishActions(this.item);
      });
    }
  }

  modifyAction(action: FormAction, extraOptions?: { index?: number }): void {
    // if there is [*] in the form action linkName, then replace it with [index]
    if (action.linkName.includes('[*]')) {
      action.linkName = action.linkName.replace(
        '[*]',
        `[${extraOptions.index}]`
      );
    }
  }

  publishActions(item: FormElementImpl, initial?: boolean): void {
    for (const action of item.publishActions) {
      if (
        this.item.hasMetActionCondition(action) ||
        (action.condition === FormActionCondition.ON_FORM_INIT && initial)
      ) {
        action.formValue = item.formControl.value;
        this.modifyAction(action, this.extraOptions);
        initial
          ? this.fas.pushInitialAction(action)
          : this.fas.publishAction(action);
      }
    }
  }
}
