<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<p-autoComplete
  [(ngModel)]="value"
  [suggestions]="results"
  [dropdown]="true"
  [placeholder]="item.placeholder"
  [multiple]="true"
  field="label"
  (completeMethod)="filterResults($event)"
  [disabled]="disabled"
  (onKeyUp)="onKeyUp($event)"
>
</p-autoComplete>
