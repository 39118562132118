/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

// Environment
import { environment } from './environments/environment';
import { environmentLoader } from './environments/environment-loader';

// Modules
import { AppModule } from './app/app.module';

environmentLoader.then((env) => {
  if (env.production) {
    enableProdMode();
  }
  environment.backendUrl = env.backendUrl;

  if (env.sentryDSN) {
    Sentry.init({
      dsn: env.sentryDSN,
    });
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
