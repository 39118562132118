/* Copyright 2023 (Unpublished) Verto Inc. */

// Models
import { FormComponent } from '@shared/models/form-component';
import { DynamicTextOptions } from '@shared/options';

export class DynamicText extends FormComponent {
  elementTypeName: string = 'DynamicText';

  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

  constructor(options?: DynamicTextOptions) {
    super(options);
    this.type = options.type;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), { type: this.type });
  }

  getTypeAsOptions() {
    return [
      { label: 'Heading 1', value: 'h1' },
      { label: 'Heading 2', value: 'h2' },
      { label: 'Heading 3', value: 'h3' },
      { label: 'Heading 4', value: 'h4' },
      { label: 'Heading 5', value: 'h5' },
      { label: 'Paragraph', value: 'p' },
    ];
  }

  getEditFormJSON(extraChildren: object[] = []) {
    return super.getEditFormJSON(
      [
        {
          elementType: 'Dropdown',
          name: 'type',
          label: 'type',
          colspan: 12,
          required: false,
          options: this.getTypeAsOptions(),
          filterBy: 'label',
          placeholder: 'Select a type',
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
