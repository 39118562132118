/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, Input } from '@angular/core';

// Interfaces
import { ModuleConfig } from '../interfaces/shell-config';

@Component({
  selector: 'app-module-outlet',
  templateUrl: './module-outlet.component.html',
})
export class ModuleOutletComponent {
  @Input() moduleConfig: ModuleConfig;
}
