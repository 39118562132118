/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { ListFormComponent } from '@shared/models/list-form-component';
import { ListFormComponentOptions } from '@shared/options';

export class RadioButton extends ListFormComponent {
  elementTypeName: string = 'RadioButton';

  options: Array<{ label: string; value: any }>;

  constructor(options: ListFormComponentOptions) {
    super(options);
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'RepeatableContainer',
          name: 'options',
          colspan: 12,
          label: 'New Repeatable Container',
          addButtonLabel: 'Add New Option',
          children: [
            {
              elementType: 'InputText',
              name: 'label',
              label: 'Label',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
            {
              elementType: 'InputText',
              name: 'value',
              label: 'Value',
              pKeyFilter: 'alphanum',
              required: true,
              colspan: 4,
            },
          ],
          subscribeActions: [
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceShow',
              type: 'TOGGLE_OFF',
            },
            {
              condition: '',
              customCondition: '',
              data: '',
              linkName: 'remoteSourceHide',
              type: 'TOGGLE_ON',
            },
          ],
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
