<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="p-fluid p-col-{{ item.colspan }}">
  <img
    [src]="item.base64String || item.remoteUrl"
    [style.width]="item.width"
    [style.height]="item.height"
    class="p-col-{{ item.colspan }}"
  />
</div>
