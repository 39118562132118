/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormData } from 'engage-common';

@Component({
  selector: 'mood-datepicker',
  templateUrl: './mood-datepicker.component.html',
  styleUrls: ['./mood-datepicker.component.scss'],
})
export class MoodDatepickerComponent {
  // todo(aleksanderbodurri): currently only works with model driven forms, add template driven form support
  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;
}
