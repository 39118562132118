/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';

// Configs
import { EngageUtilsConfig } from './engage-utils-config';
import { APP_BASE_HREF } from '@angular/common';

@Directive({
  selector: '[engage-asset]',
})
export class EngageSrcDirective implements OnInit {
  @Input() path: string;

  constructor(
    private elRef: ElementRef,
    private engageSrcConfig: EngageUtilsConfig,
    @Inject(APP_BASE_HREF) public baseHref: string
  ) {}

  ngOnInit(): void {
    if (this.engageSrcConfig.assetsPath) {
      this.elRef.nativeElement.src = `assets/${this.engageSrcConfig.assetsPath}/${this._srcPath}`;
    }
  }

  private get _srcPath(): string {
    if (this.elRef.nativeElement.src.includes(window.location.origin + this.baseHref)) {
      // In cases where the baseHref is appended, replace the full appended string
      return this.elRef.nativeElement.src.replace(window.location.origin + this.baseHref, '');
    } else {
      // Else fallback to default behavior
      return this.elRef.nativeElement.src.replace(window.location.origin + '/', '');
    }
  }
}
