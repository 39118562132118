/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';

@Component({
  selector: 'mah-spinner',
  templateUrl: './mah-spinner.component.html',
  styleUrls: ['./mah-spinner.component.scss'],
})
export class MahSpinnerComponent {}
