/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Component, DoCheck } from '@angular/core';

// Models
import { InputText } from '@shared/models/input-text';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent extends FormElementComponent {
  item: InputText;
}
