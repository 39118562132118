/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormData } from 'engage-common';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-multi-select',
  templateUrl: './mood-multi-select.component.html',
  styleUrls: ['./mood-multi-select.component.scss'],
})
export class MoodMultiSelectComponent {
  // todo(aleksanderbodurri): currently only works with model driven forms, add template driven form support
  @Input() label?: string | (() => string);
  @Input() form: UntypedFormGroup;
  @Input() formData: FormData;

  get inputLabel(): string {
    return valOrEval(this.label);
  }

  valueChange($event: any): void {
    this.formData.currentValue = $event.value;
  }
}
