<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="select-one p-grid" [attr.data-name]="item.name">
  <label class="select-title p-col-12"
    >{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label
  >
  <div
    class="select-item"
    *ngFor="let option of options; let i = index"
    (click)="clickRadio($event, option.value)"
    [ngClass]="[item.horizontal ? 'p-col-12 p-md-' + item.colspanPerItem : 'p-col-12']"
  >
    <input
      *ngIf="!item.multiselect"
      [formControl]="item.formControl"
      [className]="option.color"
      [id]="item.id + '-option-' + i"
      [name]="item.id"
      [value]="option.value"
      type="radio"
    />
    <p-checkbox
      [className]="option.color"
      [class.checked]="item.formControl?.value?.includes(option.value)"
      [class.is-disabled]="item.formControl?.disabled"
      *ngIf="item.multiselect"
      [name]="item.id"
      [value]="option.value"
      [formControl]="item.formControl"
      [inputId]="item.id + '-option-' + i"
      [style]="{ visibility: 'hidden' }"
      (onChange)="emitEvent()"
    ></p-checkbox>
    <label class="select-label" [htmlFor]="item.id + '-option-' + i">{{ option.label }}</label>
  </div>
</div>
<app-form-validators [item]="item"></app-form-validators>
