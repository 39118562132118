/* Copyright 2023 (Unpublished) Verto Inc. */

export enum FormValidatorType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  REGEX = 'REGEX',
  TIME_LESS_THAN_TODAY = 'TIME_LESS_THAN_TODAY',
  TIME_GREATER_THAN_TODAY = 'TIME_GREATER_THAN_TODAY',
  TIME_EQUAL_TO_TODAY = 'TIME_EQUAL_TO_TODAY',
}
