/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Injectable } from '@angular/core';

// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// models
import { FormAction } from '@root/models/form-action';

@Injectable()
export class FormActionService {
  private actionSubject: BehaviorSubject<object> = new BehaviorSubject<object>(
    null
  );
  private initialPublishActions: FormAction[] = [];

  // set to true if the entire form is disabled.
  public globalDisabled = false;

  constructor() {}

  getActions(): Observable<FormAction> {
    return this.actionSubject.pipe(filter((action: FormAction) => !!action));
  }

  actionMatch(
    subAction: FormAction,
    action: FormAction,
    extraOptions?: { index?: number }
  ) {
    return (
      subAction.linkName === action.linkName ||
      (extraOptions &&
        subAction.linkName.replace('[*]', '') ===
          action.linkName.replace(`[${extraOptions.index}]`, ''))
    );
  }

  filterActions(
    subscribeActions: FormAction[],
    extraOptions?: { index?: number }
  ): Observable<FormAction[]> {
    // return all the subscribe actions whose linkname's match the given action
    return this.getActions().pipe(
      map((action: FormAction) => {
        const filteredActions = subscribeActions.filter(
          (subAction: FormAction) =>
            this.actionMatch(subAction, action, extraOptions)
        );
        for (const filteredAction of filteredActions) {
          filteredAction.formValue = action.formValue;
        }
        return filteredActions;
      }),
      filter((actions: FormAction[]) => !!actions && actions.length > 0)
    );
  }

  publishAction(action: FormAction): void {
    this.actionSubject.next(action);
  }

  pushInitialAction(action: FormAction): void {
    this.initialPublishActions.push(action);
  }

  publishInitialActions(): void {
    this.initialPublishActions.forEach((action: FormAction) => {
      this.publishAction(action);
    });
  }
}
