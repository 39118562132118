/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Injectable } from '@angular/core';

// Rxjs
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface FormUpdateInterface {
  readonly?: boolean;
  extraText?: string | null;
  value?: any;
}

@Injectable({
  providedIn: 'root',
})
export class FormUpdateService {
  private formUpdateSubject: Subject<{
    fieldId: string;
    update: FormUpdateInterface;
  }> = new Subject();

  fieldSubject(fieldId: string) {
    return this.formUpdateSubject.pipe(
      filter(({ fieldId: fId }) => fId === fieldId),
      map(({ update }) => update)
    );
  }

  updateForm(fieldId: string, update: any) {
    this.formUpdateSubject.next({ fieldId, update });
  }
}
