/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Component, Input, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

// services
import { FormStateService } from '@root/services/form-state.service';

// models
import { FormComponent } from '@shared/models/form-component';

@Component({
  selector: 'app-form-validators',
  templateUrl: './form-validators.component.html',
  styleUrls: ['./form-validators.component.scss'],
})
export class FormValidatorsComponent implements OnInit {
  @Input() item: FormComponent;
  hasValidators: boolean = false;
  controlInvalid: boolean = false;
  formState: 'saving' | 'saved' | 'not-saved' | 'error';

  constructor(private fss: FormStateService) {}

  ngOnInit() {
    this.hasValidators =
      this.item.required ||
      (this.item.validators && this.item.validators.length > 0);
    if (this.hasValidators) {
      this.fss.formStateSubject.subscribe(
        (state: 'saving' | 'saved' | 'not-saved' | 'error') =>
          (this.formState = state)
      );
      this.item.formControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(() => {
          this.controlInvalid =
            this.item.formControl.dirty && this.item.formControl.invalid;
        });
    }
  }
}
