/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { RootContainerOptions } from '@shared/options';
import { FormContainer } from './form-container';

export class RootContainer extends FormContainer {
  elementTypeName: string = 'RootContainer';

  formBuilderName: string;
  themeClass: string;
  autosave: boolean;

  isReadOnly: boolean;

  customCssRules: Array<{ rule: string }>;

  constructor(options?: RootContainerOptions) {
    super(options || { children: [], name: 'root', colspan: 12 });
    this.formBuilderName = options.formBuilderName;
    this.themeClass = options.themeClass;
    this.autosave = options.autosave;
    this.affectLayout = options.affectLayout ?? true;
    this.customCssRules = options.customCssRules || [];
    this.isReadOnly = options.isReadOnly;
  }

  getContainerIds(): string[] {
    const ids = super.getContainerIds();
    ids.push('trash');
    return ids;
  }

  toJSON(): object {
    return Object.assign(super.toJSON(), {
      formBuilderName: this.formBuilderName,
      themeClass: this.themeClass,
      autosave: this.autosave,
      isReadOnly: this.isReadOnly,
      customCssRules: this.customCssRules,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return {
      elementType: 'RootContainer',
      label: '',
      children: [
        {
          elementType: 'Button',
          label: 'Save Changes',
          required: false,
          type: 'submit',
        },
        {
          elementType: 'Spacing',
          name: 'spacing',
          colspan: 4,
          required: false,
          height: '20px',
        },
        {
          elementType: 'Accordion',
          name: 'basicProperties',
          colspan: 12,
          header: 'Basic Properties',
          lazyLoad: false,
          defaultOpen: false,
          children: [
            {
              elementType: 'InputText',
              name: 'formBuilderName',
              label: 'Form Title',
              required: true,
            },
            {
              elementType: 'Dropdown',
              name: 'themeClass',
              colspan: 12,
              required: false,
              label: 'Theme Class',
              options: [
                {
                  label: 'Flow',
                  value: 'flow-theme',
                },
                {
                  label: 'Shadow',
                  value: 'drop-shadow-theme',
                },
              ],
              placeholder: 'Select an option',
            },
            {
              elementType: 'InputSwitch',
              name: 'isReadOnly',
              label: 'Form is Read Only',
              required: false,
              default: false,
            },
            {
              elementType: 'InputSwitch',
              name: 'autosave',
              label: 'Autosave On Change',
              required: false,
              default: false,
            },
            {
              elementType: 'RepeatableContainer',
              name: 'customCssRules',
              colspan: 12,
              required: false,
              children: [
                {
                  elementType: 'InputText',
                  name: 'rule',
                  colspan: '10',
                  hidden: false,
                  required: false,
                  label: 'CSS Rule',
                  inputType: 'text',
                },
              ],
              affectLayout: true,
              addButtonLabel: 'Add new CSS Rule',
              maxItems: 100,
              minItems: 0,
            },
          ],
        },
        // @ts-ignore
      ].concat(extraChildren),
    };
  }
}
