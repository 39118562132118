<!-- Copyright 2023 (Unpublished) Verto Inc. -->

{{ inputLabel }}
<ng-container [formGroup]="form">
  <p-multiSelect
    [required]="formData.validators.required"
    [options]="formData.options"
    [formControlName]="formData.controlName"
    [defaultLabel]="formData.placeholder"
    (onChange)="valueChange($event)"
    [maxSelectedLabels]="8"
    appendTo="body"
  ></p-multiSelect>
</ng-container>
