<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<ng-container *ngIf="buildable; else renderOnly">
  <div class="p-fluid" [attr.data-name]="item.name">
    <span class="p-label">
      <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
      <ng-container *ngIf="item.inputType !== 'number'; else numberInput">
        <input
          [id]="item.id"
          [type]="item.inputType"
          pInputText
          [attr.pKeyFilter]="!!item.pKeyFilter ? item.pKeyFilter : undefined"
          [formControl]="item.formControl"
          [placeholder]="item.placeholder"
        />
      </ng-container>
    </span>
    <app-form-validators [item]="item"></app-form-validators>
  </div>
</ng-container>
<ng-template #renderOnly>
  <div class="p-fluid" [attr.data-name]="item.name">
    <span class="p-label">
      <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
      <ng-container *ngIf="item.inputType !== 'number'; else numberInput">
        <input
          [id]="item.id"
          [type]="item.inputType"
          pInputText
          [attr.pKeyFilter]="!!item.pKeyFilter ? item.pKeyFilter : undefined"
          [formControl]="item.formControl"
          [placeholder]="item.placeholder"
          [maxlength]="item.maxlength ? item.maxlength : null"
          (input)="emitEvent()"
        />
      </ng-container>
    </span>
    <app-form-validators [item]="item"></app-form-validators>
  </div>
</ng-template>
<ng-template #numberInput>
  <input
    [id]="item.id"
    type="number"
    pInputText
    [attr.pKeyFilter]="!!item.pKeyFilter ? item.pKeyFilter : undefined"
    [formControl]="item.formControl"
    [placeholder]="item.placeholder"
    (input)="emitEvent()"
  />
</ng-template>
