/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

// models
import { ValidatorFactory } from '@root/models/validator-factory';
import { FormLayout } from '@shared/models/form-layout';
import { FormContainerOptions } from '@shared/options';
import { FormElement } from './form-element';
import { FormElementImpl } from './form-element-impl';

export class FormContainer extends FormElementImpl {
  children: FormElement[];
  affectLayout: boolean;

  constructor(options?: FormContainerOptions) {
    super(options);
    if (options !== undefined) {
      this.children = options.children || [];
      this.affectLayout = options.affectLayout;
      if (options.affectLayout === undefined) {
        this.affectLayout = true;
      }
    } else {
      this.children = [];
      this.affectLayout = true;
    }
  }

  getContainerIds(accumulator?: string[]): string[] {
    // DFS!! children go in the back, i go in the front..
    let ids = accumulator || [];
    this.children.forEach((childItem) => {
      const childrenIds = childItem.getContainerIds(ids);
      if (childrenIds.length > 0) {
        ids = childrenIds;
      }
    });
    ids.unshift(this.id);
    return ids;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      children: this.children.map((element) => element.toJSON()),
      affectLayout: this.affectLayout,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputSwitch',
          name: 'affectLayout',
          label: 'Affect Layout',
          required: false,
          default: true,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }

  toFormControl(): AbstractControl {
    const children = this.children.reduce(
      (
        accum: { [formName: string]: AbstractControl },
        current: FormElement
      ) => {
        // a form container that does not affect layout
        if (current instanceof FormContainer && !current.affectLayout) {
          // flatten current formControl
          const currentFormControl =
            current.toFormControl() as UntypedFormGroup;
          Object.entries(currentFormControl.controls || []).forEach(
            (entry: [string, AbstractControl]) => {
              accum[entry[0]] = entry[1];
            }
          );
        } else if (!(current instanceof FormLayout)) {
          accum[current.formName()] = current.toFormControl();
        }
        return accum;
      },
      {}
    );
    this.formControl = new UntypedFormGroup(
      children,
      ValidatorFactory.generateValidators(this)
    );
    return this.formControl;
  }
}
