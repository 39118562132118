/* Copyright 2023 (Unpublished) Verto Inc. */

// models
import { FormActionCondition } from '@root/models/form-action-condition.enum';
import { FormActionType } from '@root/models/form-action-type.enum';

export class FormAction {
  // for publish
  condition: FormActionCondition;

  // for subscribe
  type: FormActionType;
  customCondition: any;

  linkName: string;
  data: any;

  // populated based on the form value
  formValue?: any;

  constructor(json: FormActionJSON) {
    this.type = FormActionType[json.type];
    this.linkName = json.linkName;
    this.condition = FormActionCondition[json.condition];
    this.customCondition = json.customCondition;
    this.data = json.data;
  }

  toJSON(): FormActionJSON {
    return {
      type: (this.type || '').toString(),
      linkName: this.linkName,
      condition: (this.condition || '').toString(),
      customCondition: (this.customCondition || '').toString(),
      data: (this.data || '').toString(),
    };
  }
}

export interface FormActionJSON {
  type: string;
  linkName: string;
  condition: string;
  customCondition?: any;
  data: any;
}
