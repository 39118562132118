/* Copyright 2023 (Unpublished) Verto Inc. */

import { Dropdown } from '@shared/models/dropdown';
import { MultiSelectOptions } from '@shared/options';

export class MultiSelect extends Dropdown {
  elementTypeName: string = 'MultiSelect';

  maxLabelsShown: number;
  allowFreeText: boolean;

  constructor(options: MultiSelectOptions) {
    super(options);
    this.maxLabelsShown = options.maxLabelsShown || 3; // default from primeNG
    this.allowFreeText = options.allowFreeText;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      maxLabelsShown: this.maxLabelsShown,
      allowFreeText: this.allowFreeText,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'maxLabelsShown',
          label: 'Max Labels Shown',
          inputType: 'number',
          required: true,
        },
        {
          elementType: 'InputSwitch',
          name: 'allowFreeText',
          label: 'Allow free text?',
          required: true,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}
