/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Injectable } from '@angular/core';

// Rxjs
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormStateService {
  public formStateSubject: Subject<'saving' | 'saved' | 'not-saved' | 'error'>;

  constructor() {
    this.formStateSubject = new BehaviorSubject('saved');
  }
}
