/* Copyright 2023 (Unpublished) Verto Inc. */

import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'engage-progressbar',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnChanges {
  // Required Inputs
  @Input() page = -1;
  @Input() totalPages = -1;

  // Optional inputs
  @Input() barColor = '#67cc33';
  @Input() backgroundColor = '#d8d8d8';
  @Input() blockColor = '#ffffff';
  @Input() progressBarHeight = 6;

  blocks = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.totalPages) {
      return;
    }

    this.blocks = [];

    for (let i = 0; i < changes.totalPages.currentValue - 1; i++) {
      const percentage = (1 / changes.totalPages.currentValue) * (i + 1) * 100;
      this.blocks.push(`${percentage}%`);
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ProgressComponent],
  exports: [ProgressComponent],
})
export class ProgressBarModule {}
