/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Utils
import { EngageUtilsModule } from 'engage-utils';

// Prime-ng Components
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenuModule } from 'primeng/menu';

// Mood Components
import { MoodButtonComponent } from './mood-button/mood-button.component';
import { MoodCheckboxComponent } from './mood-checkbox/mood-checkbox.component';
import { MoodMultiCheckboxComponent } from './mood-multi-checkbox/mood-multi-checkbox.component';
import { MoodRadioGroupComponent } from './mood-radio-group/mood-radio-group.component';
import { MoodScreenComponent } from './mood-screen/mood-screen.component';
import { MoodScreenHeaderComponent } from './mood-screen/mood-screen-header/mood-screen-header.component';
import { MoodScreenContentComponent } from './mood-screen/mood-screen-content/mood-screen-content.component';
import { MoodScreenFooterComponent } from './mood-screen/mood-screen-footer/mood-screen-footer.component';
import { MoodRendererComponent } from './mood-renderer/mood-renderer.component';
import { MoodInputComponent } from './mood-input/mood-input.component';
import { MoodTextAreaComponent } from './mood-textarea/mood-textarea.component';
import { MoodParagraphComponent } from './mood-paragraph/mood-paragraph.component';
import { MoodAccordionComponent } from './mood-accordion/mood-accordion.component';
import { MoodTableComponent } from './mood-table/mood-table.component';
import { MoodHeaderComponent } from './mood-header/mood-header.component';
import { MoodGiantButtonComponent } from './mood-giant-button/mood-giant-button.component';
import { MoodDropdownComponent } from './mood-dropdown/mood-dropdown.component';
import { MoodSpinnerComponent } from './mood-spinner/mood-spinner.component';
import { MoodCaptchaComponent } from './mood-captcha/mood-captcha.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MoodMultiSelectComponent } from './mood-multi-select/mood-multi-select.component';
import { MoodDatepickerComponent } from './mood-datepicker/mood-datepicker.component';
import { MoodCodeDisplayComponent } from './mood-code-display/mood-code-display.component';
import { MoodContextComponent } from './mood-state/mood-context.component';

@NgModule({
  declarations: [
    MoodButtonComponent,
    MoodCheckboxComponent,
    MoodMultiCheckboxComponent,
    MoodRadioGroupComponent,
    MoodScreenComponent,
    MoodScreenHeaderComponent,
    MoodScreenContentComponent,
    MoodScreenFooterComponent,
    MoodRendererComponent,
    MoodInputComponent,
    MoodTextAreaComponent,
    MoodParagraphComponent,
    MoodAccordionComponent,
    MoodTableComponent,
    MoodHeaderComponent,
    MoodGiantButtonComponent,
    MoodDropdownComponent,
    MoodSpinnerComponent,
    MoodCaptchaComponent,
    MoodMultiSelectComponent,
    MoodDatepickerComponent,
    MoodCodeDisplayComponent,
    MoodContextComponent,
  ],
  exports: [
    MoodButtonComponent,
    MoodCheckboxComponent,
    MoodMultiCheckboxComponent,
    MoodRadioGroupComponent,
    MoodScreenComponent,
    MoodScreenHeaderComponent,
    MoodScreenContentComponent,
    MoodScreenFooterComponent,
    MoodRendererComponent,
    MoodInputComponent,
    MoodParagraphComponent,
    AccordionModule,
    MoodAccordionComponent,
    MoodTableComponent,
    MoodHeaderComponent,
    MoodGiantButtonComponent,
    MoodContextComponent,
    DropdownModule,
    MoodSpinnerComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    DropdownModule,
    ReactiveFormsModule,
    EngageUtilsModule,
    ProgressSpinnerModule,
    InputTextModule,
    InputMaskModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MenuModule,
    MultiSelectModule,
    CalendarModule,
    CheckboxModule,
  ],
})
export class MoodModule {}
