<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div
  *ngFor="let option of formData.options"
  class="container-check"
  [ngClass]="{ active: active(option.value) }"
  [formGroup]="form"
>
  <p-checkbox
    [formControl]="form.controls[formData.controlName]"
    [name]="formData.controlName"
    [label]="option.label"
    [value]="option.value"
    (onChange)="change($event, option)"
  ></p-checkbox>
</div>
