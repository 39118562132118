<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<ng-container *ngIf="buildable; else renderOnly">
  <span class="small-header">{{ item.name }}</span>
  <div
    cdkDropList
    cdkDropListOrientation="horizontal"
    [id]="item.id"
    (cdkDropListDropped)="onDragDrop($event)"
    [cdkDropListConnectedTo]="draggableElementIds"
    [cdkDropListData]="item"
    class="horizontal-list item-dropzone row"
  >
    <ng-container *ngIf="item.children.length === 0">
      <span class="text-center">Drag something to me</span>
    </ng-container>
    <app-dynamic-component
      *ngFor="let child of item.children"
      [buildable]="true"
      class="child-item col-sm-{{ child.getColSpan() }} col-md-{{ child.getColSpan() }} col-lg-{{ child.getColSpan() }}"
      [class.no-padding]="child.elementTypeName.includes('Container') || child.elementTypeName.includes('Fieldset')"
      [item]="child"
      [cdkDragData]="child"
      (click)="onComponentClick(child, $event)"
      cdkDrag
    >
    </app-dynamic-component>
  </div>
</ng-container>

<ng-template #renderOnly>
  <div class="p-label">
    <app-repeatable-container-control [item]="item" [formControl]="item.formControl"></app-repeatable-container-control>
  </div>
  <app-form-validators [item]="item"></app-form-validators>
</ng-template>
