<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="p-label" [attr.data-name]="item.name">
  <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
  <h1 *ngIf="item.type === 'h1'">{{ displayValue() }}</h1>
  <h2 *ngIf="item.type === 'h2'">{{ displayValue() }}</h2>
  <h3 *ngIf="item.type === 'h3'">{{ displayValue() }}</h3>
  <h4 *ngIf="item.type === 'h4'">{{ displayValue() }}</h4>
  <h5 *ngIf="item.type === 'h5'">{{ displayValue() }}</h5>
  <p *ngIf="item.type === 'p'">{{ displayValue() }}</p>
</div>
<app-form-validators [item]="item"></app-form-validators>
