/* Copyright 2023 (Unpublished) Verto Inc. */

// angular
import { Component, OnInit } from '@angular/core';

// services
import { BuilderService } from '@root/services/builder.service';
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';
import { FormUpdateService } from '@root/services/form-update.service';

// components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// models
import { FormElement } from '@shared/models';

@Component({ template: '' })
export class FormContainerComponent
  extends FormElementComponent
  implements OnInit
{
  public draggableElementIds: string[];

  constructor(
    fgs: FormGroupService,
    fus: FormUpdateService,
    fes: FormEventService,
    public builder: BuilderService
  ) {
    super(fgs, fus, fes);
  }

  ngOnInit() {
    this.builder.getDraggableElementIds().subscribe((ids) => {
      this.draggableElementIds = ids;
    });
  }

  onComponentClick(item: FormElement, event) {
    if (!!event) {
      event.stopPropagation();
      this.builder.setEditControlsPosition.next({
        x: event.x,
        y: event.y,
      });
    }
    this.builder.lastElementSubject.next(item);
  }

  onDragDrop($event): void {
    this.builder.onDragDrop($event);
  }

  toggleDialog(op, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    op.toggle(event);
  }

  componentSelected(component, dialog) {
    (this.item as any).children.push(component);
    this.builder.lastElementSubject.next(component);
    this.builder.onDragDropEvent.next(true);
    dialog.hide();
  }
}
