/* Copyright 2023 (Unpublished) Verto Inc. */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import { APP_KEY } from 'engage-common';

@Injectable({
  providedIn: 'root',
})
export class DistanceFilterService {
  constructor(private http: HttpClient, @Inject(APP_KEY) private _appKey: string) {}

  getAutocompleteResults(query: string) {
    return this.http.get(
      `https://nominatim.openstreetmap.org/search?format=json&limit=5&countrycodes=ca&addressdetails=1&q=${query}`
    );
  }

  filterByDistance(results: any[], filter: any) {
    const fromLatLng = L.latLng(filter.lat, filter.lon);

    results.sort((a: any, b: any) => {
      const toLatLngA = L.latLng(a._geo.lat, a._geo.lng);
      const toLatLngB = L.latLng(b._geo.lat, b._geo.lng);

      const distA = fromLatLng.distanceTo(toLatLngA);
      const distB = fromLatLng.distanceTo(toLatLngB);

      return distA - distB;
    });

    return results;
  }
}
